import React from "react";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import CustomButton from "../../../../../Components/Button/CustomButton/CustomButton";
import Clock from "../../../../../assets/lottie/clock.json";
import { useDispatch } from "react-redux";
import { toggleQuoteDetailModal } from "../../../store/store";

const InspectionAwaited = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="d-flex align-items-center gap-3 mb_12">
        <p className="component_section_name mb-0">Inspection Details</p>
        {/* <div className="pending_badge">Pending</div> */}
      </div>
      <div className="card_background p_40">
        <QuoteDynamicHeader
          title="Inspection agency details awaited!"
          subTitle="Please relax while we are fetching the third party inspection details"
          noCard
          customImage
          lottieImage={Clock}
        />

        <div className="d-flex align-items-center justify-content-center">
          <CustomButton
            buttonName="OKAY"
            handleClick={() => dispatch(toggleQuoteDetailModal())}
          />
        </div>
      </div>
    </>
  );
};

export default InspectionAwaited;
