import React from "react";
import { Controller } from "react-hook-form";
import FormInput from "../../Components/FormInput/FormInput";
import User from "../../assets/images/common/user.svg";
import Phone from "../../assets/images/common/phone.svg";
import Email from "../../assets/images/common/email.svg";
import DocFields from "./DocFields";
import RequestDetails from "./RequestDetails";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";

const NewVeichle = ({
  errors,
  control,
  veichleState,
  veichleType,
  setVeichleType,
  activeHeader,
  previousPolicyFlag,
  resetField,
}) => {
  return (
    <div className="form_body ">
      <div className="row pb_24">
        <div className="col-6">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <FormInput
                field={field}
                icon={<img src={User} alt="user" />}
                inputMode={"alpha"}
                placeholder="Customer Name"
              />
            )}
          />
          {errors.name && (
            <div className="error_class">{errors.name.message}</div>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="mobileNumber"
            render={({ field }) => (
              <FormInput
                field={field}
                icon={<img src={Phone} alt="phone" />}
                placeholder="Customer Mobile No."
                inputMode={"numeric"}
              />
            )}
          />
          {errors.mobileNumber && (
            <div className="error_class">{errors.mobileNumber.message}</div>
          )}
        </div>
      </div>
      <div className="row pb_24">
        <div className="col-6">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <FormInput
                field={field}
                icon={<img src={Email} alt="user" />}
                placeholder="Customer Email Id"
              />
            )}
          />
          {errors.email && (
            <div className="error_class">{errors.email.message}</div>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="rtoCode"
            render={({ field }) => (
              <FormInput field={field} placeholder="Enter RTO Code *" />
            )}
          />
          {errors.rtoCode && (
            <div className="error_class">{errors.rtoCode.message}</div>
          )}
        </div>
      </div>
      <div className="row pb_24">
        <Controller
          control={control}
          name="manufacturingYear"
          render={({ field }) => (
            <MultiSelect
              {...field}
              options={Array.from(
                { length: new Date().getFullYear() - 1989 },
                (v, k) => k + 1990,
              ).map((year) => ({
                value: year,
                label: year,
              }))}
              placeholder="Manufacturing Year"
              isSearchable
              isClearable
              className="select_dropdown"
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value);
              }}
              isMulti={false}
              value={field.value && { value: field.value, label: field.value }}
            />
          )}
        />
        {errors.manufacturingYear && (
          <div className="error_class mt-2">
            {errors.manufacturingYear.message}
          </div>
        )}
      </div>

      <RequestDetails
        errors={errors}
        control={control}
        setVeichleType={setVeichleType}
        veichleType={veichleType}
        resetField={resetField}
        isNewVehicle={true}
      />
      <DocFields
        errors={errors}
        control={control}
        activeHeader={activeHeader}
        veichleState={veichleState}
        isNewVehicle={true}
        previousPolicyFlag={previousPolicyFlag}
        resetField={resetField}
      />
    </div>
  );
};

export default NewVeichle;
