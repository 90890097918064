// Products Section Images
import Car from "../assets/images/products/car.svg";
import TwoWheeler from "../assets/images/products/scooter.svg";
import Commercial from "../assets/images/products/commercial.svg";
import Home from "../assets/images/products/home.svg";
import Health from "../assets/images/products/health.svg";
import Other from "../assets/images/products/other.svg";

// Quote Request Modal svg import
import { ReactComponent as CarNew } from "../assets/images/NewQuoteRequest/Car.svg";
import { ReactComponent as Bike } from "../assets/images/NewQuoteRequest/Bike.svg";
import { ReactComponent as GCV } from "../assets/images/NewQuoteRequest/GCV.svg";
import { ReactComponent as PCV } from "../assets/images/NewQuoteRequest/PCV.svg";
import { ReactComponent as Bus } from "../assets/images/NewQuoteRequest/Bus.svg";
import { ReactComponent as MiscD } from "../assets/images/NewQuoteRequest/MiscD.svg";
import moment from "moment/moment";

// Regex
export const phoneRegExp = /^[6-9]\d{9}$/;
export const pancardRegExp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const aadhaarRegExp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;

export const convertRangeArray = (str) => str?.split("-");

// save to storage
export const saveToStorage = (key, value) => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(key, value);
  }
};

// get from storage
export const getFromStorage = (key) => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }
};

// Dashboard Progress obj
export const partnerCompletionPercentages = {
  MARKSHEET_UPLOAD_PAGE: 30,
  AADHAAR_VERIFY_PAGE: 40,
  ADDRESS_DETAILS_PAGE: 40,
  NOC_VERIFICATION_PAGE: 40,
  NOC_UPLOAD_PAGE: 40,
  COURSE_PAGE: 40,
  TRAINING_STARTED_PAGE: 45,
  GI_EXAM_PENDING_PAGE: 45,
  LI_EXAM_PENDING_PAGE: 50,
  BANK_VERIFICATION_PAGE: 80,
  CANCEL_CHEQUE_UPLOAD_PAGE: 90,
  CANCEL_CHEQUE_VERIFICATION_PAGE: 90,
  GST_REGISTRATION_PAGE: 95,
  PROFILE_COMPLETED_PAGE: 100,
};

// Dashboard Modal Current Page
export const modalButtonNameObj = {
  PAN_VERIFY_PAGE: "VERIFY YOUR PAN",
  PARTNER_DETAILS_CAPTURE_PAGE: "Basic Details",
  MARKSHEET_UPLOAD_PAGE: "Upload 10th Marksheet",
  AADHAAR_VERIFY_PAGE: "Verify your Aadhar",
  BANK_VERIFICATION_PAGE: "Verify your Bank Details",
  CANCEL_CHEQUE_UPLOAD_PAGE: "Verify your Bank Details",
  GST_REGISTRATION_PAGE: "Verify your GST Details",
  ADDRESS_DETAILS_PAGE: "Verify Aadhar Address",
};

export const isExactAddress = ["Yes", "No"];

// Noc Modal Page
export const nocModalDocumentsObj = {
  LI: "Life Insurance | Max Life Insurance",
  HI: "Health Insurance | Max Life Insurance",
  GI: "General Insurance | Max Life Insurance",
  POS: "Point Of Sales",
  MARKSHEET: "10th Marksheet",
};

export const productsPendingDocumentObj = {
  LI: "Life Insurance",
  HI: "Health Insurance",
  GI: "General Insurance",
  POS: "Point Of Sales",
  MARKSHEET: "10th Marksheet",
  PAN: "PAN Card",
  AADHAAR: "Aadhaar Card",
};

// Products Section
export const productsListArray = [
  { image: Car, name: "Car" },
  { image: TwoWheeler, name: "Two Wheeler" },
  { image: Commercial, name: "Commercial" },
  { image: Home, name: "Home" },
  { image: Health, name: "Health" },
  { image: Other, name: "Others" },
];

// Policy Array
export const policyCardArray = [
  { name: "Car", count: "0" },
  { name: "Two Wheeler", count: "0" },
  { name: "Health", count: "0" },
  { name: "Term", count: "0" },
  { name: "Investment", count: "0" },
  { name: "Other", count: "0" },
];

// Remove slash for router
export const getCurrentPageName = (url) => {
  let newUrl = "";

  if (url === "/") {
    newUrl = "Dashboard";
    return newUrl;
  }

  // Remove leading slash using slice()
  if (url.startsWith("/")) {
    newUrl = url.slice(1);
  }

  // Check if hyphen exists
  if (newUrl.includes("-") || newUrl.includes("_") || newUrl.includes("/")) {
    newUrl = newUrl.replace(/[-_]/g, " ");
    newUrl = newUrl.replace(/\//g, " ");
  }

  return newUrl;
};

export const isObjEmpty = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }

  if (typeof obj === "object") {
    // For arrays and objects, check if they have any keys or elements
    return Object.keys(obj).length === 0;
  }

  if (typeof obj === "string") {
    return obj.trim().length === 0;
  }

  // For other types, check if they have a length property
  if (obj.hasOwnProperty("length")) {
    return obj.length === 0;
  }

  return false;
};

// Quote request constants
export const newQuoteRequestHeader = [
  { id: 0, key: "CAR", image: CarNew, name: "Car" },
  { id: 1, key: "TW", image: Bike, name: "Two wheeler" },
  { id: 2, key: "GCV", image: GCV, name: "GCV" },
  { id: 3, key: "PCV", image: PCV, name: "PCV" },
  { id: 4, key: "BUS", image: Bus, name: "Bus" },
  { id: 5, key: "MISC_D", image: MiscD, name: "Misc D" },
];

export const busSubTypeArray = [
  { label: "Passenger Bus", value: "PASSENGER_BUS" },
  { label: "School Bus", value: "SCHOOL_BUS" },
  { label: "Staff Bus", value: "STAFF_BUS" },
];

export const productTypeImageObj = {
  CAR: <CarNew />,
  TW: <Bike />,
  GCV: <GCV />,
  PCV: <PCV />,
  BUS: <Bus />,
  MISC_D: <MiscD />,
};

export const createNewQuoteRequestPayload = (data) => {
  // Create a new FormData object
  const formData = new FormData();

  formData.append("product", data.product);
  formData.append("vehicleType", data.vehicleType);
  formData.append("policyType", data.policyType);
  formData.append("name", data.name);
  formData.append("mobileNumber", data.mobileNumber);
  formData.append("email", data.email);
  if (data.vehicleType === "NEW") formData.append("rtoCode", data.rtoCode);
  if (data.vehicleType === "OLD")
    formData.append("registrationNumber", data.registrationNumber);
  if (data.vehicleType === "OLD")
    formData.append("registrationDate", data.registrationDate);
  if (data.idv) {
    let newIdv = data.idv.replace(/,/g, "");
    formData.append("idv", newIdv);
  }
  if (data.manufacturingYear)
    formData.append("manufacturingYear", data.manufacturingYear);
  // formData.append("insurerAddonsData", JSON.stringify(data.insurerAddonsData));
  if (data.insurerList) {
    formData.append("insurerList", JSON.stringify(data.insurerList));
  }
  if (data.addOns) {
    formData.append("addons", JSON.stringify(data.addOns));
  }
  if (data.INVOICE_COPY) formData.append("INVOICE", data.INVOICE_COPY);
  // if (data.PAN) formData.append("PAN", data.PAN);
  // if (data.AADHAAR_BACK) formData.append("AADHAAR_BACK", data.AADHAAR_BACK);
  // if (data.AADHAAR_FRONT) formData.append("AADHAAR_FRONT", data.AADHAAR_FRONT);
  if (data.OTHER_DOC) formData.append("OTHER_DOC", data.OTHER_DOC);
  if (data.PERMIT) formData.append("PERMIT", data.PERMIT);
  if (data.RC) formData.append("RC", data.RC);
  if (data.isPyp != null) formData.append("isPyp", data.isPyp ? "yes" : "no");
  if (data.pypClaim != null)
    formData.append("pypClaim", data.pypClaim ? "yes" : "no");
  if (data.PYP) formData.append("PYP", data.PYP);
  if (data.pypExpiryDate) formData.append("pypExpiryDate", data.pypExpiryDate);
  if (data.ncb) formData.append("ncb", data.ncb);

  return formData;
};

export const createPolicyMappingRequestPayload = (data) => {
  // Create a new FormData object
  const formData = new FormData();

  formData.append("product", data.product);
  formData.append("vehicleType", data.vehicleType);
  formData.append("policyNumber", data.policyNumber);
  formData.append(
    "registrationNumber",
    data.registrationNumber || data.rtoCode,
  );
  formData.append("contactNumber", data.contactNumber);
  formData.append("registrationDate", data.registrationDate || new Date());
  if (data.product === "BUS") {
    formData.append("vehicleOwner", data.vehicleOwner);
    formData.append("vehicleSubClass", data.vehicleSubClass);
  }
  formData.append("PERMIT", data.PERMIT);
  formData.append("POLICY_COPY", data.POLICY_COPY);
  formData.append("OTHERS", data.OTHERS);

  return formData;
};

// Get quote request list
export const quoteRequestListDate = (date) => {
  const currentDate = new Date();
  const providedDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  providedDate.setHours(0, 0, 0, 0);

  const timeDifference = currentDate - providedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    return "Created Today";
  } else if (daysDifference === 1) {
    return "Created Yesterday";
  } else if (daysDifference >= 2) {
    return `Created ${daysDifference} Days Ago`;
  } else {
    // Handle future dates
    return "created in the future";
  }
};

// Get quote request list
// export const quoteRequestActivityTime = (timestamp) => {
//   const now = new Date();
//   const inputDate = new Date(timestamp);

//   const diffInDays = Math.floor((now - inputDate) / (1000 * 60 * 60 * 24));

//   const formattedTime = inputDate.toLocaleTimeString([], {
//     hour: "numeric",
//     minute: "2-digit",
//     hour12: true,
//   });

//   // const period = inputDate.getHours() >= 12 ? "PM" : "AM";

//   if (diffInDays <= 3) {
//     // x days ago
//     return `${diffInDays === 0 ? "Today" : diffInDays === 1 ? "Yesterday" : `${diffInDays} days ago`} at ${formattedTime}`;
//   } else {
//     // Show the full date after 3 days
//     const formattedDate = inputDate.toLocaleDateString();
//     return `${formattedDate} at ${formattedTime}`;
//   }
// };

export const quoteRequestActivityTime = (timestamp) => {
  const inputDate = moment(timestamp);
  const now = moment();

  const diffInDays = now.diff(inputDate, "days");

  const formattedTime = inputDate.format("h:mm A");

  if (diffInDays === 0) {
    return `Today at ${formattedTime}`;
  } else if (diffInDays === 1) {
    return `Yesterday at ${formattedTime}`;
  } else if (diffInDays <= 3) {
    return `${diffInDays} days ago at ${formattedTime}`;
  } else {
    return `${inputDate.format("MM/DD/YYYY")} at ${formattedTime}`;
  }
};

export const quoteDocumentsObj = {
  INVOICE: "Invoice",
  AADHAAR_BACK: "Aadhaar Back",
  AADHAAR_FRONT: "Aadhaar Front",
  PAN: "PAN",
  RC: "RC",
};

export const uploadFileAllowedFormats = [
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const creataMultipleDocUploadPayload = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && Object.keys(data[key]).length > 0) {
      formData.append(key, data[key].file, data[key].fileName);
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const createFormDataWithFileAndJSON = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
    } else if (Array.isArray(data[key]) && data[key].length > 0) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (
      typeof data[key] === "object" &&
      Object.keys(data[key]).length > 0
    ) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const filteredData = (data) =>
  Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== undefined),
  );

export const quoteRequestListingStatusObj = {
  QUOTE_REQUESTED: "Quote Requested",
  DOCUMENT_VERIFICATION_STARTED: "Document Verification Started",
  DOCUMENT_REUPLOAD: "Document Re-Upload",
  DOCUMENT_VERIFICATION: "Document Verification",
  SHARE_QUOTE: "Quote Pending",
  QUOTE_SHARED: "Quote Shared",
  QUOTE_CHANGE_REQUEST: "Quote Change Requested",
  FILL_CUSTOMER_DETAILS: "Fill Customer Details",
  QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL: "Insurer Approval Pending",
  INSPECTION_PORTAL_REQUESTED: "Inspection Portal Requested",
  INSPECTION_DETAILS_SHARED: "Inspection Details Shared",
  INSPECTION_PORTAL_REQUEST_RAISE: "Inspection Portal Request Raise",
  QUOTE_ACCEPTED_SEND_FOR_APPROVAL: "Quote Approval Pending at Insurer",
  QUOTE_ACCEPTED: "Quote Accepted",
  PAYMENT_LINK_PENDING: "Payment Link Pending",
  PAYMENT_LINK_SHARED: "Payment Link Shared",
  PAYMENT_PROOF_TAKEN: "Payment Details Under Verification",
  PAYMENT_DETAILS_APPROVED: "Payment Details Approved",
  CASE_CLOSED: "Case Closed",
  FILL_KYC_DOCUMENT: "Submit KYC Document",
  VERIFY_KYC_DOCUMENT: "Verify KYC Document",
  UPLOAD_POLICY_COPY: "Upload Policy Copy",
};

export const QuoteStatusList = {
  REQUESTED: "REQUESTED",
  CHANGE_REQUESTED: "CHANGE_REQUESTED",
  SHARED: "SHARED",
  UNAVAILABLE: "UNAVAILABLE",
  ACCEPTED: "ACCEPTED",
  ACCEPTED_WITH_INSPECTION: "ACCEPTED_WITH_INSPECTION",
  DECLINED: "DECLINED",
};

// Quote request
export const getQuoteRequestColor = (status) => {
  let color = "";
  // use quoteRequestListingStatusObj keys to get the status
  switch (status) {
    case "QUOTE_REQUESTED":
    case "PAYMENT_LINK_SHARED":
    case "QUOTE_SHARED":
      color = "#0691FC";
      break;
    case "CASE_CLOSED":
    case "QUOTE_ACCEPTED":
    case "PAYMENT_DETAILS_APPROVED":
      color = "#09CE1D";
      break;
    case "FILL_CUSTOMER_DETAILS":
    case "INSPECTION_PORTAL_REQUEST_RAISE":
    case "INSPECTION_PORTAL_REQUESTED":
    case "DOCUMENT_REUPLOAD":
      color = "#E73535";
      break;
    case "DOCUMENT_VERIFICATION":
      color = "#FFBF1A";
      break;
    default:
      color = "#FFBF1A";
  }
  return color;
};

export const declineReasonOptions = [
  { value: "Already Insured", label: "Already Insured" },
  { value: "High Premium", label: "High Premium" },
  { value: "Coverage Not Sufficient", label: "Coverage Not Sufficient" },
  { value: "Others", label: "Others" },
];

export const mappingRequestStatusObj = {
  DOCUMENT_VERIFICATION_STARTED: "Docs Under Verification",
  DOCUMENT_REUPLOAD: "Submit Documents",
  DOCUMENT_VERIFIED: "Document Verified",
  POLICY_MAPPING_REJECTED: "Policy Rejected, Case Closed",
  POLICY_MAPPING_APPROVED: "Policy Mapping Approved",
};

export const mappingRequestListingColorObj = {
  DOCUMENT_VERIFICATION_STARTED: "#FFBF1A",
  DOCUMENT_REUPLOAD: "#0691FC",
  DOCUMENT_VERIFIED: "#09CE1D",
  POLICY_MAPPING_REJECTED: "#E73535",
  POLICY_MAPPING_APPROVED: "#09CE1D",
};
export const fileTypesToUpload = ["pdf", "jpeg", "jpg", "png"];
export const mandatoryDocFields = [
  {
    documentType: "PAN",
    label: "PAN Card",
  },
  {
    documentType: "AADHAAR_FRONT",
    label: "Aadhar Front",
  },
  {
    documentType: "AADHAAR_BACK",
    label: "Aadhar Back",
  },
];

export const policyTypeOptions = {
  COMPREHENSIVE: "Comprehensive Policy",
  THIRD_PARTY: "Third Party Policy",
  SAOD: "SAOD Policy",
};

export const dashboardUserPageStates = {
  MARKSHEET_UPLOAD_PAGE: "MARKSHEET_UPLOAD_PAGE",
  AADHAAR_VERIFY_PAGE: "AADHAAR_VERIFY_PAGE",
  ADDRESS_DETAILS_PAGE: "ADDRESS_DETAILS_PAGE",
  NOC_VERIFICATION_PAGE: "NOC_VERIFICATION_PAGE",
  NOC_UPLOAD_PAGE: "NOC_UPLOAD_PAGE",
  COURSE_PAGE: "COURSE_PAGE",
  TRAINING_STARTED_PAGE: "TRAINING_STARTED_PAGE",
  GI_EXAM_PENDING_PAGE: "GI_EXAM_PENDING_PAGE",
  LI_EXAM_PENDING_PAGE: "LI_EXAM_PENDING_PAGE",
  BANK_VERIFICATION_PAGE: "BANK_VERIFICATION_PAGE",
  CANCEL_CHEQUE_UPLOAD_PAGE: "CANCEL_CHEQUE_UPLOAD_PAGE",
  CANCEL_CHEQUE_VERIFICATION_PAGE: "CANCEL_CHEQUE_VERIFICATION_PAGE",
  GST_REGISTRATION_PAGE: "GST_REGISTRATION_PAGE",
  PROFILE_COMPLETED_PAGE: "PROFILE_COMPLETED_PAGE",
};
