import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import CustomButton from "../../Button/CustomButton/CustomButton";
import MultiSelect from "../../MultiSelect/MultiSelect";
import { ReactComponent as InfoIcon } from "../../../assets/images/common/info.svg";
import "./DeclineAllModal.scss";
import QuoteDynamicHeader from "../../QuoteDynamicHeader/QuoteDynamicHeader";
import DeclineSuccessLottie from "../../../assets/lottie/declined.json";

const DeclineAllModal = ({
  declineAllFlag,
  setDeclineAllFlag,
  declineReasonOptions,
  declineReason,
  setDeclineReason,
  declineReasonError,
  handleToDeclineAllQuotes,
  isDeclineSuccess,
}) => {
  return (
    <CustomModal
      isOpen={declineAllFlag}
      toggle={() => setDeclineAllFlag(false)}
      modaltitle={"Decline All Quotes"}
      modalbody={
        <>
          {!isDeclineSuccess ? (
            <div className="decline_all_modal_container">
              <div className="decline_all_modal_reason_container">
                <div className="decline_all_modal_reason_options">
                  <MultiSelect
                    options={declineReasonOptions}
                    selectedOptions={[]}
                    onChange={(option) =>
                      option && option.value
                        ? setDeclineReason(option.value)
                        : setDeclineReason("")
                    }
                    isClearable={true}
                    placeholder="Select Reason"
                    isMulti={false}
                    className="reason_dropdown"
                  />
                  {declineReasonError && (
                    <div className="error_class">{declineReasonError}</div>
                  )}
                </div>
              </div>
              <div className="decline_all_note">
                <InfoIcon width={20} height={20} />
                <span className="decline_all_note_text">
                  If you decline all quotes, then this case will be closed
                </span>
              </div>
              <div className="decline_all_modal_btns">
                <CustomButton
                  buttonName="CANCEL"
                  handleClick={() => setDeclineAllFlag(false)}
                  alignCenter
                  className="cancel_btn"
                  btnNameClass="cancel_btn_name"
                />
                <CustomButton
                  buttonName="Decline"
                  handleClick={handleToDeclineAllQuotes}
                  alignCenter
                  className="decline_btn"
                  btnNameClass="decline_btn_name"
                />
              </div>
            </div>
          ) : (
            <div className="decline_all_modal_container">
              <QuoteDynamicHeader
                title="Our Team is working on it"
                subTitle="Our Team will update you shortly on the status"
                noCard
                customImage
                lottieImage={DeclineSuccessLottie}
              />
              <div className="decline_all_modal_btns">
                <CustomButton
                  buttonName="Okay"
                  handleClick={() => setDeclineAllFlag(false)}
                  alignCenter
                  className="button"
                  btnNameClass="cancel_btn_name"
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default DeclineAllModal;
