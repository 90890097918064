import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import CustomButton from "../../../../../Components/Button/CustomButton/CustomButton";
import {
  quoteDocumentsObj,
  uploadFileAllowedFormats,
} from "../../../../../Constants/Constants";
import FileSelector from "../../../../../Components/FileSelector/FileSelector";
import ChequeDetails from "./ChequeDetails";
import "./DocumentUpload.scss";
import { ReactComponent as BinIcon } from "../../../../../assets/images/common/bin_icon.svg";
// import {  } from "react-feather";

const DocumentUpload = ({ docs, onSubmit, chequeDetails }) => {
  const [chequeDetailsArray, setChequeDetailsArray] = useState([]);

  const addChequeDetails = () => {
    setChequeDetailsArray([...chequeDetailsArray, {}]);
  };
  const removeCheque = (indexToRemove) => {
    setChequeDetailsArray(
      chequeDetailsArray.filter((_, i) => i !== indexToRemove),
    );
    const currentChequeDetails = getValues("chequeDetails");
    const updatedChequeDetails = currentChequeDetails.filter(
      (_, index) => index !== indexToRemove,
    );
    setValue("chequeDetails", updatedChequeDetails);
    const otherFormValues = getValues();

    // refresh the form to show the updated values
    reset({
      ...otherFormValues,
      chequeDetails: updatedChequeDetails,
    });
  };

  const isDocReupload = docs.some((item) => item.documentRejectReason);

  const isChequeReupload = chequeDetailsArray.some(
    (item) => item.chequeStatus && item.chequeStatus !== "VERIFIED",
  );

  const schema = yup.object().shape({
    ...docs.reduce((acc, item) => {
      acc[item.documentType] = yup
        .mixed()
        .required("Document is required")
        .test("fileType", "Invalid file format", (value) => {
          if (!value) return false;
          return uploadFileAllowedFormats.includes(value.type);
        })
        .test("fileSize", "The file is too large", (value) => {
          return value && value.size <= 5242880;
        });
      return acc;
    }, {}),
    // allow null check details
    chequeDetails: yup
      .array()
      .of(
        yup.object().shape({
          chequeNumber: yup.string().required("Cheque Number is required"),
          issueDate: yup.string().required("Cheque Date is required"),
          amount: yup.string().required("Cheque Amount is required"),
          bankName: yup.string().required("Cheque Bank is required"),
          chequeStatus: yup.string().optional().nullable(),
          chequeUrl: yup.string().optional().nullable(),
          // chequeFile is required if chequeStatus is not VERIFIED
          chequeFile: yup
            .mixed()
            .test({
              name: "conditionalValidation",
              test: function (value) {
                const chequeStatus = this.parent.chequeStatus;
                // If chequeStatus is not "VERIFIED" and chequeFile is empty, return false
                if (chequeStatus !== "VERIFIED" && !value) {
                  throw new yup.ValidationError(
                    "Cheque File is required",
                    value,
                    this.path,
                  );
                }
                return true;
              },
            })
            .nullable(),
        }),
      )
      .nullable(),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      chequeDetails: [],
    },
  });

  useEffect(() => {
    if (chequeDetails) {
      setChequeDetailsArray(chequeDetails);
      setValue("chequeDetails", chequeDetails);
    }
  }, [chequeDetails, setValue]);

  return (
    <>
      <div className="d-flex flex-row space_between align_center">
        <p className="component_section_name mb_0"> Customer KYC Documents </p>
        <span className="pending_status ">
          {" "}
          {isDocReupload ? "Re-Upload documents" : "Pending"}{" "}
        </span>
      </div>
      <div className="card_background">
        <div className="quote_requeste_doc_upload_container p_24">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {docs?.map((item, index) => (
                <div key={index}>
                  <Controller
                    name={item.documentType}
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <div>
                        <div className="doc_card">
                          <div className="doc_name_content">
                            <p className="doc_name mb-0">
                              {quoteDocumentsObj[item?.documentType] ??
                                item?.documentType}
                            </p>
                          </div>
                          {!field.value && item.documentRejectReason && (
                            <div className="doc_status_content">
                              <p className="doc_status mb-0">
                                {item.documentRejectReason}
                              </p>
                            </div>
                          )}

                          <div className="doc_btn_content">
                            <FileSelector
                              id={item.documentType}
                              file={field.value}
                              field={field}
                              handleFileChange={(file) => field.onChange(file)}
                            />
                          </div>
                        </div>
                        {errors[item.documentType] && (
                          <p className="error_class text-start mb-3 mt-0">
                            {errors[item.documentType]?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex flex-column gap_12">
              <div>
                <div className="kyc_details_container">
                  <div className=" section_name mb_12 mt_12">
                    {chequeDetailsArray.length > 0 && "Cheque Details"}
                  </div>
                  {!(isDocReupload || isChequeReupload) &&
                    chequeDetailsArray.length < 2 && (
                      <div
                        className="add_cheque_details"
                        onClick={addChequeDetails}
                      >
                        {chequeDetailsArray.length === 0
                          ? "Add cheque details (if any)"
                          : "Add one more cheque details (if any)"}
                      </div>
                    )}
                  {isChequeReupload && (
                    <span className="pending_status ">Re-Upload documents</span>
                  )}
                </div>
                <div
                  className="d-flex flex-column gap_12"
                  style={{ gap: "12px" }}
                >
                  {chequeDetailsArray?.map((item, index) => (
                    <div style={{ position: "relative" }}>
                      <Controller
                        key={index}
                        name={`chequeDetails[${index}]`}
                        control={control}
                        reset={reset}
                        // defaultValue={chequeDetails ? chequeDetails[index] : {}}
                        render={({ field }) => (
                          <ChequeDetails
                            item={item}
                            field={field}
                            errors={errors}
                            index={index}
                            reset={reset}
                          />
                        )}
                      />
                      {(!item.chequeStatus || item.chequeStatus === "") && (
                        <div
                          style={{
                            position: "absolute",
                            top: "-6%",
                            left: "-5px",
                            background: "white",
                          }}
                          onClick={() => removeCheque(index)}
                        >
                          <BinIcon />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <CustomButton
                  type="submit"
                  alignCenter
                  className="doc_submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DocumentUpload;
