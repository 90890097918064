import React from "react";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/searching.json";

const DocumentVerification = () => {
  return (
    <div>
      <p className="component_section_name mb_12">Shared Quotes</p>
      <QuoteDynamicHeader
        title="No Quotes Shared yet!"
        subTitle="Please relax while we verify the documents"
        customImage
        lottieImage={Clock}
      />
    </div>
  );
};

export default DocumentVerification;
