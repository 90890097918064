// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_OPTIONS_DATA,
  GET_FAVORITE_POLICY_DEALS,
  GET_POLICY_DEALS,
} from "../../../axios/apiendPoint";

const initialState = {
  isFirstLoad: true,
  isLoading: false,
  optionsData: {},
  favoritePolicyDeals: [],
  policyDeals: {
    isLoading: false,
    declineDeals: [],
    specialDeals: [],
    marketDeals: [],
  },
  notes: [],
};

export const fetchoptionsData = createAsyncThunk(
  "grid/fetchoptionsData",
  async ({ axiosGet, params, searchKey }) => {
    const response = await axiosGet(GET_OPTIONS_DATA, params);
    return { data: response?.data, searchKey: searchKey };
  },
);

export const fetchFavoritePolicyDeals = createAsyncThunk(
  "grid/fetchFavoritePolicyDeals",
  async ({ axiosGet, params }) => {
    const response = await axiosGet(GET_FAVORITE_POLICY_DEALS, params);
    return { data: response?.data };
  },
);

export const fetchPolicyDeals = createAsyncThunk(
  "grid/fetchPolicyDeals",
  async ({ axiosGet, params }) => {
    const response = await axiosGet(GET_POLICY_DEALS, params);
    return { data: response?.data };
  },
);

export const optionsDataSlice = createSlice({
  name: "grid",
  initialState,
  reducers: {
    resetOptionsData: (state) => {
      state.isLoading = false;
      state.optionsData = {};
      state.favoritePolicyDeals = [];
      state.policyDeals = {
        isLoading: false,
        declineDeals: [],
        specialDeals: [],
        marketDeals: [],
      };
      state.notes = [];
    },
    resetPolicyDeals: (state) => {
      state.isFirstLoad = true;
      state.policyDeals = {
        isLoading: false,
        declineDeals: [],
        specialDeals: [],
        marketDeals: [],
      };
    },
    updatePolicyDeals: (state, action) => {
      let policyDetail = action.payload;
      let policyDeals = state.policyDeals;
      if (policyDetail?.dealType === "DECLINE_DEAL") {
        policyDeals.declineDeals = policyDeals.declineDeals.map((deal) =>
          deal.dealId === policyDetail.dealId ? policyDetail : deal,
        );
      } else if (policyDetail?.dealType === "SPECIAL_DEAL") {
        policyDeals.specialDeals = policyDeals.specialDeals.map((deal) =>
          deal.dealId === policyDetail.dealId ? policyDetail : deal,
        );
      } else if (policyDetail?.dealType === "MARKET_DEAL") {
        policyDeals.marketDeals = policyDeals.marketDeals.map((deal) =>
          deal.dealId === policyDetail.dealId ? policyDetail : deal,
        );
      }
      state.policyDeals = policyDeals;
    },
    updateFavoritePolicyDeals: (state, action) => {
      let { index, policyDetail } = action.payload;
      let favoritePolicyDeals = state.favoritePolicyDeals;
      favoritePolicyDeals[index] = policyDetail;
      state.favoritePolicyDeals = favoritePolicyDeals;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchoptionsData.fulfilled, (state, action) => {
      let newOptionsData = action?.payload?.data || {};
      const searchKey = action?.payload?.searchKey;

      if (searchKey === "makeSubstring" || searchKey === "modelSubstring") {
        newOptionsData["rtoData"] = state?.optionsData["rtoData"] || [];
        // don't clear old options
        newOptionsData["makes"] = Array.from(
          new Map(
            [
              ...(action?.payload?.data["makes"] || []),
              ...(state?.optionsData["makes"] || []),
            ].map((item) => [item.value, item]),
          ).values(),
        );

        // Combine old and new models, ensuring unique options based on 'value'
        newOptionsData["models"] = Array.from(
          new Map(
            [
              ...(action?.payload?.data["models"] || []),
              ...(state?.optionsData["models"] || []),
            ].map((item) => [item.value, item]),
          ).values(),
        );
      }
      if (searchKey === "rtoSubstring") {
        newOptionsData["makes"] = state?.optionsData["makes"] || [];
        newOptionsData["models"] = state?.optionsData["models"] || [];
        // don't clear old options for rto
        newOptionsData["rtoData"] = Array.from(
          new Map(
            [
              ...(action?.payload?.data["rtoData"] || []),
              ...(state?.optionsData["rtoData"] || []),
            ].map((item) => [item.value, item]),
          ).values(),
        );
      }

      state.notes = action?.payload?.data?.notes || [];
      state.isLoading = false;
      state.optionsData = newOptionsData || {};
    });
    builder.addCase(fetchoptionsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchoptionsData.rejected, (state) => {
      state.isLoading = false;
      state.optionsData = {};
      state.notes = [];
    });

    builder.addCase(fetchFavoritePolicyDeals.fulfilled, (state, action) => {
      if (action?.payload?.data) {
        state.favoritePolicyDeals = action?.payload?.data?.gridFavourites || [];
        state.notes = action?.payload?.data?.notes || [];
      } else {
        state.favoritePolicyDeals = [];
        state.notes = [];
      }
    });
    builder.addCase(fetchFavoritePolicyDeals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFavoritePolicyDeals.rejected, (state) => {
      state.isLoading = false;
      state.favoritePolicyDeals = [];
      state.notes = [];
    });

    builder.addCase(fetchPolicyDeals.fulfilled, (state, action) => {
      state.policyDeals = {
        isLoading: false,
        declineDeals: action?.payload?.data?.declineDeals || [],
        specialDeals: action?.payload?.data?.specialDeals || [],
        marketDeals: action?.payload?.data?.marketDeals || [],
      };
      state.notes = action?.payload?.data?.notes || [];
      state.isLoading = false;
      state.isFirstLoad = false;
    });
    builder.addCase(fetchPolicyDeals.pending, (state) => {
      state.isLoading = true;
      state.policyDeals.isLoading = true;
    });
    builder.addCase(fetchPolicyDeals.rejected, (state) => {
      state.isLoading = false;
      state.policyDeals = {
        declineDeals: [],
        specialDeals: [],
        marketDeals: [],
        isLoading: false,
      };
      state.notes = [];
    });
  },
});
export const {
  resetOptionsData,
  resetPolicyDeals,
  updatePolicyDeals,
  updateFavoritePolicyDeals,
} = optionsDataSlice.actions;
export default optionsDataSlice.reducer;
