import React, { Fragment, useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import FormInput from "../../Components/FormInput/FormInput";
import CustomField from "../../Components/CustomFields/CustomFields";
import { Input } from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Calendar } from "react-feather";
import { isVeichleTypeOptions } from "./RequestDetails";
import { policyTypeOptions } from "../../Constants/Constants";
import { number } from "yup";

export const isPypAvailableOptions = ["Yes", "No"];

const PreviousPolicy = ({
  errors,
  control,
  previousPolicyFlag,
  setPreviousPolicyFlag,
  claimPolicyFlag,
  setClaimPolicyFlag,
  veichleType,
  setVeichleType,
  resetField,
}) => {
  useEffect(() => {
    if (previousPolicyFlag[0] === "No") {
      resetField("pypExpiryDate", null);
      resetField("ncb", null);
      setClaimPolicyFlag(["No"]);
    } else {
      resetField("ncb", null);
    }
  }, [previousPolicyFlag]);

  useEffect(() => {
    if (claimPolicyFlag[0] === "Yes") {
      resetField("ncb", null);
    }
  }, [claimPolicyFlag]);

  return (
    <Fragment>
      <div className="divider mt-3">
        <div className="divider-text ">Previous Year Policy Details</div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-0">
          <div className="me-5 fw-bold width_178">Do you have a PYP ?</div>{" "}
          <div className="pt-2">
            <CustomField
              value={previousPolicyFlag}
              setvalue={setPreviousPolicyFlag}
              options={isPypAvailableOptions}
            />
          </div>
        </div>
        {previousPolicyFlag[0] === "Yes" && (
          <div className="col-4">
            {/* <Controller control={control} name="pypExpiryDate" render={({ field }) => <Input type="date" {...field} placeholder="Registration Date" />} /> */}
            <Controller
              control={control}
              name="pypExpiryDate"
              render={({ field }) => (
                <div className="d-flex align-items-center">
                  <Flatpickr
                    {...field}
                    placeholder="Expiry date"
                    className="flat_picker_date"
                  />{" "}
                  <Calendar color="#0691FC" className="margin__30" />
                </div>
              )}
            />
            {errors.pypExpiryDate && (
              <div className="error_class">{errors.pypExpiryDate.message}</div>
            )}
          </div>
        )}
      </div>

      {previousPolicyFlag[0] === "Yes" &&
        (veichleType[0] === policyTypeOptions.COMPREHENSIVE ||
          veichleType[0] === policyTypeOptions.SAOD) && (
          <div className="d-flex align-items-center justify-content-between mt_24">
            <div className="d-flex align-items-center">
              <div className="me-5 fw-bold width_178">Claim in PYP ?</div>{" "}
              <div className="pt-2">
                <CustomField
                  value={claimPolicyFlag}
                  setvalue={setClaimPolicyFlag}
                  options={isPypAvailableOptions}
                />
              </div>
            </div>
            {claimPolicyFlag[0] === "No" && (
              <div className="col-4">
                <Controller
                  control={control}
                  name="ncb"
                  render={({ field }) => (
                    <FormInput
                      inputMode={"numeric"}
                      type={"number"}
                      field={field}
                      placeholder="Enter NCB %"
                    />
                  )}
                />
                {errors.ncb && (
                  <div className="error_class">{errors.ncb.message}</div>
                )}
              </div>
            )}
          </div>
        )}
    </Fragment>
  );
};

export default PreviousPolicy;
