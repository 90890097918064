import React from "react";
import FormInput from "../../../../../Components/FormInput/FormInput";
import "./ChequeDetails.scss";
import FileSelector from "../../../../../Components/FileSelector/FileSelector";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Eye } from "react-feather";

const ChequeDetails = ({ field, errors, reset, index, item }) => {
  const handleChange = (e) => {
    field.onChange({
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="cheque_container">
      <div>
        <FormInput
          field={field}
          name="chequeNumber"
          placeholder={"Cheque No."}
          value={field?.value?.chequeNumber}
          onChange={(e) => handleChange(e)}
          maxLength={6}
          inputMode={"numeric"}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails &&
          errors?.chequeDetails[index]?.chequeNumber && (
            <p className="error_class text-start mb-3 mt-0">
              {errors?.chequeDetails[index]?.chequeNumber?.message}
            </p>
          )}
      </div>
      <div>
        <FormInput
          field={field}
          name="bankName"
          value={field?.value?.bankName}
          placeholder={"Bank Name"}
          onChange={(e) => handleChange(e)}
          inputMode={"alpha"}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails && errors?.chequeDetails[index]?.bankName && (
          <p className="error_class text-start mb-3 mt-0">
            {errors?.chequeDetails[index]?.bankName?.message}
          </p>
        )}
      </div>
      <div>
        <div className="d-flex align-items-center">
          <Flatpickr
            {...field}
            name="issueDate"
            value={field?.value?.issueDate}
            onChange={(date) =>
              handleChange({ target: { name: "issueDate", value: date[0] } })
            }
            placeholder="Issue Date"
            className="flat_picker_date"
            disabled={
              item && item.chequeStatus && item.chequeStatus === "VERIFIED"
            }
          />
        </div>
        {errors?.chequeDetails && errors?.chequeDetails[index]?.issueDate && (
          <p className="error_class text-start mb-3 mt-0">
            {errors?.chequeDetails[index]?.issueDate?.message}
          </p>
        )}
      </div>
      <div>
        <FormInput
          field={field}
          name="amount"
          inputMode={"currency"}
          value={field?.value?.amount}
          placeholder={"Amount"}
          onChange={(e) => handleChange(e)}
          maxLength={21}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails && errors?.chequeDetails[index]?.amount && (
          <p className="error_class text-start mb-3 mt-0">
            {errors?.chequeDetails[index]?.amount?.message}
          </p>
        )}
      </div>

      {item.chequeUrl && item?.chequeStatus === "VERIFIED" ? (
        <div className={"view_cheque"}>
          <a
            href={item?.chequeUrl}
            target="_blank"
            rel="noreferrer"
            className="d-flex align_center gap-2"
          >
            <Eye size={16} />
            <span>View</span>
          </a>
        </div>
      ) : (
        <div>
          <FileSelector
            id={`chequeFile${index}`}
            file={field?.value?.chequeFile}
            fileNameToDisplay={
              field?.value?.chequeFile?.name?.length > 7
                ? field?.value?.chequeFile?.name?.slice(0, 7) + ".."
                : field?.value?.chequeFile?.name
            }
            handleFileChange={(file) =>
              handleChange({ target: { name: "chequeFile", value: file } })
            }
          />
          {errors?.chequeDetails &&
            errors?.chequeDetails[index]?.chequeFile && (
              <p className="error_class text-start mb-3 mt-0">
                {errors?.chequeDetails[index]?.chequeFile?.message}
              </p>
            )}
        </div>
      )}
    </div>
  );
};

export default ChequeDetails;
