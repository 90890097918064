import React, { useState } from "react";
import Select, { components } from "react-select";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  isMulti,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "#fff";
  if (isFocused) bg = "#cccccc";
  if (isActive) bg = "#B2D4FF";
  if (isSelected) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    margin: 0,
    padding: "8px 12px",
    width: "100%",
    cursor: "pointer",
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  let disable = isDisabled;
  let selected = isSelected;
  if (children === "Select All" && rest.selectProps.isAllSelected) {
    selected = true;
    disable = true;
  }
  // let multi = isMulti;
  // if(children==="select ALl" && rest.selectProps.isMulti)

  return (
    <components.Option
      {...rest}
      isDisabled={disable}
      isFocused={isFocused}
      isSelected={selected}
      getStyles={getStyles}
      innerProps={props}
    >
      {rest.selectProps.isMulti && (
        <input
          style={{ marginRight: 10 }}
          type="checkbox"
          checked={selected}
          disabled={disable}
        />
      )}
      {children}
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const value = props.getValue();
  const input = children[1];

  // if all options are selected, show "All" instead of all the selected options
  if (value.length > 1 && value.length === props.options.length - 1) {
    return (
      <components.ValueContainer {...props}>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            padding: "0px 10px",
            // width: "calc(100% - 25px)",
          }}
        >
          <div className="align-items-center">All Selected</div>
        </div>
        {input}
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {value.length > 1 ? (
        <>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              padding: "0px",
              // width: "calc(100% - 10px)",
            }}
          >
            <div className="align-items-center">
              {value[0] && value[0].label && value[0].label.length > 8
                ? value[0].label.slice(0, 8) + "."
                : value[0].label}

              {value.length > 1 && `+${value.length - 1}`}
            </div>
          </div>
          {input}
        </>
      ) : value.length === 1 && props.selectProps.isMulti ? (
        <>
          <div className="d-flex align-items-center justify-content-between px-2">
            <div className="d-flex align-items-center">
              {value[0] && value[0].label && value[0].label.length > 8
                ? value[0].label.slice(0, 8) + "."
                : value[0].label}
            </div>
          </div>
          {input}
        </>
      ) : (
        <>{children}</>
      )}
    </components.ValueContainer>
  );
};

const MultiSelect = ({
  options,
  allowSelectAll,
  selectedOptions,
  onChange,
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "50px",
      border: "1px solid #cccccc",
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Poppins",
      "&:hover": {
        border: "1px solid #4D9AFF",
      },
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #4D9AFF",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      maxWidth: "100px", // Adjust the width as needed
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (base) => ({
      ...base,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      flexWrap: "nowrap",
      "& > div": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "hsl(0, 0%, 50%)",
      },
    }),
  };

  const properties = {
    isMulti: true,
    isSearchable: true,
    ...props,
    className: `multi_select ${props.className}`,
    onChange: onChange,
    styles: customStyles,
    closeMenuOnSelect: props.isMulti === false,
    hideSelectedOptions: false,
    components: {
      Option: InputOption,
      ValueContainer: ValueContainer,
    },
  };

  if (allowSelectAll) {
    let allOption = {
      label: "Select All",
      value: "selectAll",
    };

    let newOptions = [];
    let isAllSelected =
      props.value && props.value.length === options?.length ? true : false;

    if (options) {
      newOptions = [allOption, ...options];
    }

    if (props.value?.length === options?.length) {
      return (
        <Select
          {...properties}
          options={newOptions}
          isAllSelected={isAllSelected}
          onChange={(selected) => onChange(selected)}
        />
      );
    }

    return (
      <Select
        {...properties}
        options={newOptions}
        isAllSelected={isAllSelected}
        onChange={(selected) => {
          if (
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return onChange(options);
          }
          return onChange(selected);
        }}
      />
    );
  }

  return <Select {...properties} options={options} />;
};

export default MultiSelect;
