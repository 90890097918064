import React from "react";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/searching.json";

const DocumentVerified = () => {
  return (
    <div>
      <p className="component_section_name mb_12">Shared Quotes</p>
      <QuoteDynamicHeader
        title="Documents Verified, Quotes Pending!"
        subTitle="We are fetching the quotes from Insurers"
      />
    </div>
  );
};

export default DocumentVerified;
